import { useEffect } from "react";
import { useRealm } from "./contexts/realm.context";
import { Outlet } from "react-router-dom";
import FooterComponent from "./components/general/footer.component";
import HeaderComponent from "./components/general/header.component";
import { HelmetProvider } from 'react-helmet-async';

export default function App() {
    const { anonymousLogin } = useRealm();
    
    useEffect(() => {
        anonymousLogin(); // eslint-disable-next-line
    }, [])

    return (
        <HelmetProvider>
            <div className="app">
                <div className="app-header">
                    <HeaderComponent />
                </div>

                <div className="app-content">
                    <Outlet />
                </div>
                
                <div className="app-footer">
                    <FooterComponent />
                </div>
            </div>
        </HelmetProvider>
    );
}