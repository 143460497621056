import "../../styles/components/general/header.component.scss"
import { Link, useLocation } from "react-router-dom";

export default function HeaderComponent() {
    const location = useLocation();
    
    function load() {
        if (location.pathname !== "/") {
            window.location.href = "/";
        } else {
            window.location.reload();
        }
    }

    return (
        <div className="inner">
            <div className="left">
                <Link to="/"><span className="title">randomwod</span></Link>
            </div>

            <div className="right">
                <button type="button" onClick={() => load()} >Get some!</button>
            </div>
        </div>
    )
}