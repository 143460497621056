import { useEffect } from 'react'
import '../styles/components/workout.component.scss'
import TagsComponent from './tags.component'
import { Link } from 'react-router-dom'

export default function WorkoutComponent( { workout, details=false, link=false }) {

    async function buttonCopyLink() {
        try {
            navigator.clipboard.writeText(process.env.REACT_APP_ROOT_URL + "workouts/" + workout.meta.slug)    
        } catch (error) {
            
        }
    }

    async function buttonShare() {
        try {
            navigator.share({title: workout.title, url: process.env.REACT_APP_ROOT_URL + "workouts/" + workout.meta.slug})    
        } catch (error) {
            
        }
    }

    return (
        <div className="workout-component">
            {link ? 
                <Link to={"/workouts/" + workout?.meta.slug}>
                    <div className="workout">
                        <div className="watermark">
                            randomwod.com<br/>
                            randomwod.com<br/>
                            randomwod.com<br/>
                        </div>
                        
                        {workout?.mode && <p className="mode">{workout?.mode}</p>}
                        {workout?.title && <p className="title">{workout?.title}</p>}
                        {workout?.description &&
                            (
                                <>
                                    <p className="description">{workout?.description}</p>
                                </>
                            )
                        }
                    </div>
                </Link>
                :
                <div className="workout">
                    <div className="watermark">
                        randomwod.com<br/>
                        randomwod.com<br/>
                        randomwod.com<br/>
                    </div>
                    {workout?.mode && <p className="mode">{workout?.mode}</p>}
                    {workout?.title && <p className="title">{workout?.title}</p>}
                    {workout?.description &&
                        (
                            <>
                                <p className="description">{workout?.description}</p>
                            </>
                        )
                    }
                </div>  
            }

            <div className="actions">
                <div className="actions">
                    <button type="button" onClick={() => buttonShare()}>Share</button>
                    <button type="button" onClick={() => buttonCopyLink()}>Copy link</button>    
                </div>
            </div>

            {details === true &&
                (<div className="workout-details">
                    {workout.instruction && 
                        (
                            <>
                                <div className="instructions">
                                    <h4>Instructions</h4>
                                    <p>{workout?.instruction}</p>
                                </div>
                            </>
                        )
                    }
                    
                    {workout.exercise.length > 0 &&
                        (
                            <>
                                <div className="exercises">
                                    <h4>Exercises</h4>
                                    <TagsComponent tags={workout?.exercise} />
                                </div>
                            </>
                        )
                    }

                    {workout.equipment.length > 0 && 
                        (
                            <>
                                <div className="equipment">
                                    <h4>Equipment</h4>
                                    <TagsComponent tags={workout?.equipment} />
                                </div>
                            </>
                        )
                    }

                    {workout.affiliate.length > 0 && 
                        (
                            <>
                                <div className="affiliate">
                                    <h4>Affiliate Links</h4>
                                    {workout.affiliate.map((item, index) => {
                                        return(
                                            <p><a key={index} href={item.url} target="_blank" rel="noreferrer">{item.title}</a></p>
                                        )
                                    })}
                                    <p className="note">These are affiliate links that redirects you to a vendor page.</p>
                                </div>
                            </>
                        )
                    }


                </div>)
            }
        </div>
    )
}