import "../styles/components/tags.component.scss";

export default function TagsComponent( { tags }) {

    return (
        <div className="tags">
            {tags.map((tag, index) => {
                return(<div key={index} className="tag">{tag}</div>)
            })}
        </div>
    )
}