import { createContext, useContext, useState } from "react";
import * as Realm from "realm-web";

export const RealmContext = createContext();

export function RealmProvider({ children }) {
    const app = new Realm.App({id: process.env.REACT_APP_MONGODB_APP_ID});
    const [user, setUser] = useState(null);

    async function anonymousLogin() {
        try {
            if (app.currentUser === null) {
            
                const credentials = Realm.Credentials.anonymous();
                const authenticatedUser = await app.logIn(credentials);
                setUser(authenticatedUser);
            } else {
                setUser(app.currentUser)
            }
        } catch (error) {
            
        }
    }

    return (
        <RealmContext.Provider value={{ user, setUser, anonymousLogin }}>
            {children}
        </RealmContext.Provider>
    )
}

export function useRealm() {
    const realmContext = useContext(RealmContext);
    if (realmContext === null) {
        throw new Error("useRealm() used outside of context!")
    }
    return realmContext;
}