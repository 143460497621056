import '../styles/global.scss'
import '../styles/pages/home.page.scss'
import { useEffect, useState } from "react";
import SeoComponent from "../components/general/seo.component";
import { useData } from "../contexts/data.context";
import { useRealm } from "../contexts/realm.context";
import WorkoutComponent from "../components/workout.component";
import LoadingComponent from '../components/general/loading.component';

export default function HomePage() {
    const { readRandomWorkout} = useData();
    const { user } = useRealm();
    const [ workout, setWorkout ] = useState()
    
    useEffect(() => {
        if (user) {
            load()
        } // eslint-disable-next-line
    }, [user])
    
    async function load() {
        if (user) {
            setWorkout(await readRandomWorkout());
        }
    }

    return (
        <div className="page home">
            <SeoComponent
                title="Home | randomwod.com"
                description="RANDOMWOD Home"
                name="randomwod.com"
                type="page" />

            {workout ? <WorkoutComponent workout={workout} details={false} link={true}/> : <LoadingComponent />}
        </div>
    )
}