import { createContext, useContext, useState } from "react";
import { useRealm } from "./realm.context";

export const DataContext = createContext(null);

export function DataProvider({ children }) {
    const { user } = useRealm()
    const [ workout, setWorkout ] = useState(null)

    async function readRandomWorkout() {
        try {
            const data = await user?.callFunction("readRandomWorkout");
            return await data[0]
        } catch (error) {
            console.error(error)
        }
    }

    async function readWorkoutBySlug(slug) {
        try {
            const data = await user?.callFunction("readWorkoutBySlug", slug);
            setWorkout(await data[0]);
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <DataContext.Provider value={{ workout, readRandomWorkout, readWorkoutBySlug }} >
            {children}
        </DataContext.Provider>
    )
}

export function useData() {
    const dataContext = useContext(DataContext)
    if (dataContext == null) {
        throw new Error("useData() called outside of context?")
    }
    return dataContext
}