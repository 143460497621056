
import { Link, useRouteError } from "react-router-dom";
import FooterComponent from "../components/general/footer.component";
import HeaderComponent from "../components/general/header.component";

export default function NotFoundPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="app">
            <div className="app-header">
                <HeaderComponent />
            </div>

            <div className="app-content">
                <div className="page error">
                    <h1>Oops!</h1>
                    <p>Not found!</p>
                    <p>
                        <i>{error?.statusText || error?.message}</i>
                    </p>
                    <Link to="/"><button type="button">Back to home</button></Link>
                </div>
            </div>

            <div className="app-footer">
                <FooterComponent />
            </div>
        </div>
    )
}