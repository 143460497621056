import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useData } from "../contexts/data.context";
import { useRealm } from "../contexts/realm.context";
import SeoComponent from "../components/general/seo.component";
import WorkoutComponent from "../components/workout.component";

export default function WorkoutDetailPage() {
    const { user } = useRealm();
    const { workout, readWorkoutBySlug } = useData();
    const location = useLocation();
    
    useEffect(() => {
        if (user) { 
            const slug = location.pathname.split("/")[2]
            readWorkoutBySlug(slug);
        } // eslint-disable-next-line
    }, [user, location])

    return (
        <>
            {workout &&
                (
                    <>
                        <SeoComponent
                            title={"Workout " + workout?.title + " | randomwod.com"}
                            description={workout?.excerpt ? workout.excerpt : workout.description}
                            name="randomwod.com"
                            type="workout" />
                        
                        <WorkoutComponent workout={workout} details={true}/>
                    </>
                )
            }
        </>
    )
}