import '../../styles/components/general/loading.component.scss'

export default function LoadingComponent() {
    return(
        <div className="loading">
            <div className="inner">
                <p>Loading...</p>
            </div>
        </div>
        
    )
}