import "../../styles/components/general/footer.component.scss"

export default function FooterComponent() {
    return (
        <div className="inner">
            <h3 className="title">randomwod.com</h3>
            <p className="copyright">
                © 2024 RANDOMWOD.COM. All Rights reserved.
            </p>
        </div>
    )
}