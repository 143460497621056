import React from 'react';
import ReactDOM from 'react-dom/client';
import { DataProvider } from './contexts/data.context';
import { RealmProvider } from './contexts/realm.context';
import './styles/global.scss'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './app';
import HomePage from './pages/home.page';
import ErrorPage from './pages/error.page';
import WorkoutDetailPage from './pages/workout.detail.page';
import ReactGA from 'react-ga4';
import NotFoundPage from './pages/notfound.page';
import "@fontsource/roboto";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/100.css";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);
ReactGA.send("pageview");

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <HomePage />
      },
      {
        path: "/workouts/:slug",
        element: <WorkoutDetailPage />
      }
    ]
  },
  {
    path: "*",
    element: <NotFoundPage />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RealmProvider>
      <DataProvider>
        <RouterProvider router={router} />
      </DataProvider>
    </RealmProvider>
  </React.StrictMode>
);